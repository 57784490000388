import React from 'react';
import styles from "./Progress.scss";

function Progress() {
  return (
    <main>
    <div className={styles.title}>
      <div className={styles.title_icon}>
        <h1>Progress</h1>
      </div>
    </div>
  </main>
  )
}

export default Progress