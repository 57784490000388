import "./App.css";
import React, {useState, useEffect} from 'react';
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Container from "./components/Container/Container";
import RightNavbar from "./components/RightNavbar/RightNavbar";
import Dashboard from "./components/Dashboard/Dashboard";

import NavContext from "./Context/NavContext";
import CourseOutline from "./components/CourseOutline/CourseOutline";
import Assignments from "./components/Assignments/Assignments";
import Schedule from "./components/Schedule/Schedule";
import Progress from "./components/Progress/Progress";

import fire from "./fire";

// import Form from "./components/LoginPage/Form";
// import LoginPage from "./components/LoginPage/support/LoginPage_Container/LoginPage";
import Login from "./components/LoginPage/support/Login";
import CourseVideos from "./components/CourseVideos/CourseVideos";


function App() {
  const [nav, setNav] = useState(false);
  const value = { nav, setNav };
  const [user, setUser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [hasAccount, setHasAccount] = useState(false);

  const clearInputs = () => {
    setEmail('')
    setPassword('')
  }

  const clearErrors = () => {
    setEmailError('')
    setPasswordError('')
  }

      //Login function
  const handleLogin = () => {
    clearErrors();
    fire.auth()
    .signInWithEmailAndPassword(email, password)
    .catch(error => {
      switch (error.code) {
        case 'auth/invalid-email':
        case 'auth/user-disabled':
        case 'auth/user-not-found':
          setEmailError(error.message);
          break;
        case 'auth/wrong-password':
          setPasswordError(error.message);
          break;
        default :
          setEmailError(error.message);;
          break;
      }
    })
  }

  //Function for logout
  const handleLogout = () => {
    fire.auth().signOut();
  }

  //Authentication listener
  const authListener = () => {
    fire.auth().onAuthStateChanged(user => {
      if ( user ){
        clearInputs();
        setUser(user);
      } else { setUser('') }
    })
  }

  //React listener.
  useEffect(() => {
    authListener();
  },);

  return (
    <div className="App">
      { user ? (
        // <Dashboard handleLogout={handleLogout} />
        <NavContext.Provider value={value}>
      {/* <Login/> */}
        <Navbar />
      
        <Container
          stickyNav={<RightNavbar />}
          content={
            <Routes>
              {/* <Route path="/" element={<Form />} /> */}
              <Route path="/dashboard" element={<Dashboard handleLogout={handleLogout}/>} />
              <Route path="/Course_Outline" element={<CourseOutline />} />
              <Route path="/Schedule" element={<Schedule />} />
              <Route path="/Assignments" element={<Assignments />} />
              <Route path="/progress" element={<Progress />} />
              <Route path="/week1" element={<CourseVideos />} />
              <Route path="*" element={<main>NOT FOUND</main>} />
            </Routes>
          }
        />
      </NavContext.Provider>
      ) : (
          <Login
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            handleLogin={handleLogin}
            // handleSignup={handleSignup}
            hasAccount={hasAccount}
            setHasAccount={setHasAccount}
            emailError={emailError}
            passwordError={passwordError}
          />
      )}
    </div>
  );
}

export default App;