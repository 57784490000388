// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import { getAnalytics } from "firebase/analytics";
// import firebase from 'firebase';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD2hbTutS_nXS_HQqT9vVd57pe40Yz0PT8",
  authDomain: "fir-login-f24c9.firebaseapp.com",
  projectId: "fir-login-f24c9",
  storageBucket: "fir-login-f24c9.appspot.com",
  messagingSenderId: "5622500873",
  appId: "1:5622500873:web:22e0060c369bed37f632d8",
  measurementId: "G-JMPXS7ZD7Y"
};

// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export default fire;