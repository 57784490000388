//STYLES
import styles from "../Navbar/Navbar.module.scss";

//CONTEXT
import { useContext } from "react";
import NavContext from "../../Context/NavContext";

//REACT ROUTER
import { NavLink } from "react-router-dom";

//ICONS
import {
  MdOutlineDashboard,
  MdOutlineDateRange,
  MdOutlineShowChart,
  MdOutlineAssignment,
  MdOutlineLogout,
} from "react-icons/md";


import { FaTimes } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { BiFoodMenu } from "react-icons/bi";

import Logo from "../../assets/images/Logo1.png";
// import Profile from "../../assets/images/profile_photo.png";

import ProfileId from "./support/ProfileId";

const componentData = [
  {
    picture : {},
    name: "Tomiwa",
    ID: "XR_CO01",
  },
]

const NavProfile = () => {
  

  return (
  //     <div className={styles.profile_picture_container}>
  //     <img src={picture} className= {styles.display_profile} alt="profile_picture" />
  //     <h4 className={styles.title}>{name}</h4>
  //     <h3 className={styles.sub_title}>{ID}</h3>
  // </div>
  <div>
    {
      componentData.map((object, i)=>{
          return <ProfileId
          key={i}
          picture={object.picture }
          name={object.name}
          ID={object.ID} 
          />
      })
    }
  </div>
  );
};

export {NavProfile};



const NavUrl = ({ url, icon, description }) => {
  const { nav, setNav } = useContext(NavContext);
  const checkWindowSize = () => {
    if (window.innerWidth < 1024) setNav(!nav);
  };

  return (
    <li className={styles.li_navlink}>
      <NavLink
        to={`${url}`}
        className={({ isActive }) => (isActive ? styles.active : undefined)}
        onClick={() => checkWindowSize()}
      >
        {icon}
        <span className={styles.description}>{description}</span>
      </NavLink>
    </li>
  );
};

const Navbar = () => {
  const { nav, setNav } = useContext(NavContext);

  return (
    <div
      className={`${styles.navbar_container} ${
        nav ? styles.navbar_mobile_active : undefined
      }`}
    >
      <nav className={nav ? undefined : styles.nav_small}>
        {/* LOGO */}
        <div className={styles.logo}>
          <img src={Logo} className={styles.logo_icon} alt=""/>
          <FaTimes
            className={styles.mobile_cancel_icon}
            onClick={() => {
              setNav(!nav);
            }}
          />
        </div>

        {/* MENU */}
        <ul className={styles.menu_container}>
          {/* <img src={Profile} className= {styles.display_profile} alt="profile_picture" /> */}
          <div className={styles.profileId}>
          {/* <ProfileId 
          picture={Profile}
          name='Tomiwa'
          ID='jmgvb12'
          /> */}
          </div>
          
          {/* FIRST CATEGORY */}

          <NavUrl
            url="/dashboard"
            icon={<MdOutlineDashboard />}
            description="Dashboard"
          />
          <NavUrl
            url="Course_Outline"
            icon={<BiFoodMenu />}
            description="Course Outline"
          />
          <NavUrl
            url="Schedule"
            icon={<MdOutlineDateRange />}
            description="Schedule"
          />
          <NavUrl 
          url="Assignments" 
          icon={<MdOutlineAssignment />} 
          description="Assignments" />

          <NavUrl
            url="progress"
            icon={<MdOutlineShowChart />}
            description="Progress"
          />

          {/* SECOND CATEGORY */}
          <span className={`${styles.categories} ${styles.second_category}`}>
            {nav ? "More" : <BsThreeDots />}
          </span>
        </ul>

        {/* LOGOUT BUTTON */}
        <div
          className={`${styles.btn_logout}`}
          onClick={() => {
            setNav(!nav);
          }}
        >
          <MdOutlineLogout />
        </div>
      </nav>

      <div
        className={nav ? styles.mobile_nav_background_active : undefined}
        onClick={() => {
          setNav(!nav);
        }}
      ></div>
    </div>
  );
};

export default Navbar;
