import styles from "./Assignment.module.scss";
import { IoChatboxOutline } from "react-icons/io5";
import { BsArrowRight } from "react-icons/bs";

import Person1 from "../../../assets/images/Person1.png";
import Person2 from "../../../assets/images/Person2.png";
import Person3 from "../../../assets/images/Person3.png";
import Person4 from "../../../assets/images/Person4.png";
import Person5 from "../../../assets/images/Person5.png";

const Act = ({ icon, icon_background, content }) => {
  return (
    <div className={styles.activity}>
      <div className={styles.icon} style={{ background: `${icon_background}` }}>
        {icon}
      </div>
      <div className={styles.content}>
        <span>{content}</span>
        <div className={styles.view}>
          <span>
            View
            <BsArrowRight />
          </span>
        </div>
      </div>
    </div>
  );
};

Act.defaultProps = {
  icon: <IoChatboxOutline />,
  icon_background: "rgb(85, 79, 232)",
  content: "Lorem ipsum dolor sit amet consectetur.",
};

const Assignment = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>Week 1 Assignment</h2>
      </div>
      <div className={styles.today}>
        <h3 className={styles.activity_day}>Come up with Hoft New Website WireFrame</h3>
        <div className={styles.activities}>
          <div>
            Participants (<span>5</span>)
          </div>
          <div className={styles.calendar}>
        <div className={styles.people}>
          <div className={styles.img_container}>
            <img src={Person1} alt="people" />
          </div>
          <div className={styles.img_container}>
            <img src={Person2} alt="people" />
          </div>
          <div className={styles.img_container}>
            <img src={Person3} alt="people" />
          </div>
          <div className={styles.img_container}>
            <img src={Person4} alt="people" />
          </div>
          <div className={styles.img_container}>
            <img src={Person5} alt="people" />
          </div>
          <div className={styles.img_container}>
            {/* <AiOutlinePlus /> */}
          </div>
        </div>
        <div className={styles.options}>
          {/* <button>
            Add view <GoPlus />
          </button> */}
        </div>
      </div>
      </div>
      </div>
      
      <div className={styles.footer_buttons}>
          <button className={styles.preview_button}>
            Preview
          </button>
          <button className={styles.next_button}>
            Next
          </button>
      </div>
    </div>
  );
};

export default Assignment;