import styles from "./Schedule.scss";

const Schedule = () => {

  return (
    <main>
        <div className={styles.title_icon}>
          <h1>Schedule</h1>
        
        </div>
        
    </main>
  );
};

export default Schedule;
