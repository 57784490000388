import React from 'react';
import './Form.scss';
// import './support/validateInfo';

// import logo from './Images/hoft_logo.png';

const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            // backgroundColor: color,
            height: 0
        }}
    />
);

const Form = (props) => {
    
    const {
        email, 
        setEmail, 
        password, 
        setPassword, 
        handleLogin, 
        emailError, 
        passwordError} = props;

  return (
    <div className='form-container'>
    <div className='form-content-left'>
        
    </div>
   
    <div className='form-content-right'>
    
        
    
    <div className='login'>
    {/* <img src={logo} /> */}
        <div className='loginContainer'>
            {/* <lable>Username</lable> */}
            
            <h5 className='login-text'>
            Login
            </h5>
            <p>Enter Details below to Login</p>
        
            <label className='form-label'>Email</label>
            <input 
            type='email' 
            autoFocus 
            required 
            placeholder='Enter your email'
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            />
            <p className='errorMsg'>{emailError}</p>
           
            <br/>
            <label className='form-label'>Password</label>
            <input 
            type='password'
            autoFocus
            required
            placeholder='Enter your password' 
            value={password}
            onChange={(e) => setPassword(e.target.value) } 
            />
            <p className='errorMsg'>{passwordError}</p>
            
            <span>Forget Password?</span>
            <ColoredLine color="#D7DCE0" />
            <div className='btnContainer'>
            <button onClick={handleLogin}>Login</button>
                {/* {hasAccount ? (
                    <>
                        <button onClick={handleLogin}>Login</button>
                        <p>Don't have an account ? <span onClick={() => setHasAccount(!hasAccount)}>Sign Up</span></p>
                    </>
                ) : (
                    <>
                        <button onClick={handleSignup}>Login</button>
                        <p>Have an account ? <span onClick={() => setHasAccount(!hasAccount)}>Sign In</span></p>
                    </>
                )} */}
            </div>
        </div>
    </div>
    </div>
    </div>
   
  )
}

export default Form;