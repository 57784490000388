import styles from "./Dashboard.module.scss";

// import styles from "./support/Charts/Analytics.module.scss";

import DashboardCard from "./support/DashboardCard";

import Menu from "../../assets/icons/Dashboard_Icons/Menu.png";
import ComMenu from "../../assets/icons/Dashboard_Icons/ComMenu.png";

import Assignments from "../../assets/icons/Dashboard_Icons/Assignments.png";
import CompAssign from "../../assets/icons/Dashboard_Icons/CompAssign.png";

import Assignment from "./support/Assignment";
import Analytics from "./support/Charts/Analytics";
import CompletedCourses from "./support/CompletedCourses";


const Statistics = ({ sum, percentage, title }) => {
  return (
    <div className={styles.statistic}>
      <div className={styles.overview}>
        <span className={styles.sum}>{sum}</span>
        <span
          className={`${styles.percentage} ${
            percentage > 7 ? styles.percetange_green : styles.percentage_orange
          }`}
        >{`${percentage > 0 ? "+" : ""}${percentage}%`}</span>
        </div>
      <h3 className={styles.statistic_title}>{title}</h3>
    </div>
  );
};

Statistics.defaultProps = {
  sum: 0,
  percentage: 0,
  title: " Lorem, ipsum.",
};


const Dashboard = ({handleLogout}) => {
  return (
    <main className={styles.container}>
      <div>
        <h1>
          Dashboard
        </h1>
      </div>
      <div className={styles.lines}>
          <DashboardCard image={Menu} title="Total Course Outline" courses="12" />
          <DashboardCard image={ComMenu} title="Completed Course Outline" courses="12" />
          <DashboardCard image={Assignments} title="Total Assignments" courses="12" />
          <DashboardCard image={CompAssign} title="Completed Assignments" courses="12" />
        </div>    
       <Analytics />
       <div className={styles.activity_tabs}>
        <div className={styles.tasks}>
          <div className={styles.activity}>
            <div>
            Assignment
            </div>
          
          <Assignment />
          
        </div>
        
        <div className={styles.activity}>
          <div>
          Completed Courses
          </div>
          <CompletedCourses />
          </div>
        </div> 
      </div>
      <button onClick={handleLogout}>Logout</button>
    </main>
  );
};

export default Dashboard;