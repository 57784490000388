import styles from "../support/CourseOutlineCard.module.scss";

const CourseOutlineCard = (props = { 
    color : "",
    week: "",
    weekColor: "",
    title: '',
    subtitle : '', 
    date: "",
    duration: "",
    url:"",
    
  }) => {

  return (
    <div className={styles.card} target="_blank" type="button"
    onClick={(e) => {
      e.preventDefault();
      window.location.href=`${props.url}`;
      }}>
      <div className={styles.card_content_left} style={{backgroundColor: props.color}}>
            {/* {`${props.color}`} */}
        </div>
        <div className={styles.card_content_right}>
        <h4 className={styles.week} style={{color: props.weekColor}}>{`${props.week}`}</h4>
        <h4 className={styles.title}>{`${props.title}`} </h4>
        <h3 className={styles.sub_title}>{`${props.subtitle}`}</h3>
        {/* <div className={styles.courses}>
          {`${props.courses}`}
        </div> */}
        <div className={styles.timestamp}>
          <div className={styles.date}><span>Date : </span>{`${props.date}`}</div>
          <div className={styles.duration}><span>Duration : </span>{`${props.duration}`}</div>
        </div>
      </div>
    </div>
  );
};

export default CourseOutlineCard;