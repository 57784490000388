import styles from "./Assignments.scss";

const Assignments = () => {
  return (
    <main>
      <div className={styles.title}>
        <div className={styles.title_icon}>
          <h1>Assignments</h1>
        </div>
      </div>
    </main>
  );
};

export default Assignments;
