import styles from "./DashboardCard.module.scss";


const DashboardCard = ({ image, title, courses, }) => {

  return (
    <div className={styles.card}>
        <img src={`${image}`} className={styles.dash_icons} alt=""/>
        <h3>{`${title}`}</h3>
        <div className={styles.courses}>
          <span>{`${courses}`}</span>
      </div>
    </div>
  );
};

export default DashboardCard;
