import styles from "./CompletedCourses.module.scss";

import thumb1 from "../../../assets/images/thumb1.png";
import thumb2 from "../../../assets/images/thumb2.png";

import { BsThreeDots } from "react-icons/bs";


const CompletedCourses = () => {
  return (
    <div className={styles.container}>
      <div className={styles.first_block}>
        {/* <div className={styles.thumbnail}>
        
        </div> */}
        <img src={thumb1} alt="Thumbnail_1" />
        <div className={styles.course_names}>
        <h4>Figma : User research to usability Testing 1</h4>
        <p>Come up with Hoft New Website WireFrame </p>
        </div>
        <div className={styles.options_button}>
        <span className={`${styles.categories} ${styles.second_category}`}>
            { <BsThreeDots />}
          </span>
        </div>
      </div>
      <div className={styles.second_block}>
      {/* <div className={styles.thumbnail}>
          </div> */}
      <img src={thumb2} alt="Thumbnail_2" />
          <div className={styles.course_names}>
            <h4>Figma : User research to usability Testing 2</h4>
            <p>Come up with Hoft New Website WireFrame </p>
          </div>
          <div className={styles.options_button}>
          <span className={`${styles.categories} ${styles.second_category}`}>
            { <BsThreeDots />}
              </span>
            </div>
        </div>
    </div>
  );
};

export default CompletedCourses;