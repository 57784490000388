import CourseVideos from "../CourseVideos/CourseVideos";
import styles from "./CourseOutline.module.scss";

import CourseOutlineCard from "./support/CourseOutlineCard";

// import Assignments from "../../assets/icons/Dashboard_Icons/Assignments.png";
// import CompAssign from "../../assets/icons/Dashboard_Icons/CompAssign.png";

const componentData = [
  {
    week: "Week 1",
    weekColor: "#6568D6",
    color : "#6568D6",
    title : 'Figma : User research to usability Testing',
    subtitle : 'Come up with Hoft\'s New Website WireFrame', 
    date: "12/12/2022",
    duration: "2 hours",
    url: <CourseVideos />,
  },

    {
    week: "Week 2",
    weekColor: "#F58634",
    color : "#F58634", 
    title : 'Loading...',
    subtitle : 'You will have Access to this Course once you complete week 2 and Assesment', 
    date: "17/12/2022",
    duration: "4 hours",
    url: "https://www.youtube.com/watch?v=RV_S5FR5Kko",
    },
    {
      week: "Week 3",
      weekColor: "#109CF1",
      color : "#109CF1", 
      title : 'Loading...',
      subtitle : 'You will have Access to this Course once you complete week 3 and Assesment', 
      date: "17/12/2022",
      duration: "4 hours",
      url: "https://www.youtube.com/watch?v=dQUyK4ZaM4s",
      },
      {
        week: "Week 4",
        weekColor: "#FF4E68",
        color : "#FF4E68", 
        title : 'Loading...',
        subtitle : 'You will have Access to this Course once you complete week 4 and Assesment', 
        date: "17/12/2022",
        duration: "4 hours",
        url: "",
        },
        {
          week: "Week 5",
          weekColor: "#192A3E",
          color : "#192A3E", 
          title : 'Loading...',
          subtitle : 'You will have Access to this Course once you complete week 5 and Assesment', 
          date: "17/12/2022",
          duration: "4 hours",
          url: "",
          },
          {
            week: "Week 6",
            weekColor: "#000367",
            color : "#000367", 
            title : 'Loading...',
            subtitle : 'You will have Access to this Course once you complete week 6 and Assesment', 
            date: "17/12/2022",
            duration: "4 hours",
            url: "",
            },
            
] 

function CourseOutline()

{
  return (
    <main>
      <div className={styles.title}>
        <h1>Course Outline</h1>
      </div>
      <div className={styles.cards_layout}>
          
      {
                    componentData.map((object, i)=>{
                        return <CourseOutlineCard
                        key={i}
                        week={object.week}
                        weekColor={object.weekColor} 
                        color={object.color }
                        title={object.title} 
                        subtitle={object.subtitle} 
                        courses= {object.courses}
                        date= {object.date}
                        duration= {object.duration}
                        url= {object.url}

                        />
                    })
                }
                              
        </div>      
    </main>
  );
};

export default CourseOutline;
